const windowResize = () => {
  let sW = document.documentElement.clientWidth;
  let sH = document.documentElement.clientHeight;
  console.log(sW);
  console.log(sH);
  let main = document.querySelector('main');
  if (sW >= sH) {
    main.classList.add('w_base');
    main.classList.remove('h_base');
  } else {
    main.classList.add('h_base');
    main.classList.remove('w_base');
  }
  if( (sW*9) > (sH*16)){
    main.classList.add('v_w_base');
    main.classList.remove('v_h_base');
  }else{
    main.classList.add('v_h_base');
    main.classList.remove('v_w_base');
  }
}
window.addEventListener("resize", (event) => {
  windowResize();
});

export default windowResize;