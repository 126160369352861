const beside = () => {
  const pageTop = (document.getElementById("pageTop") != null);
  if (pageTop) {

    let sW = document.documentElement.clientWidth;
    if (sW > 750) {

      const listWrapperEl = document.getElementById('jsSideScrollListWrapper');
      const listEl = document.getElementById('jsSideScrollList');

      gsap.to(listEl, {
        x: () => -(listEl.clientWidth - listWrapperEl.clientWidth),
        ease: 'none',
        scrollTrigger: {
          trigger: listWrapperEl,
          start: 'top top',
          end: () => `+=${listEl.clientWidth - listWrapperEl.clientWidth}`,
          scrub: true,
          pin: true,
          anticipatePin: 1,
          invalidateOnRefresh: true,
          scroller: ".side-scroll-list",
        },
      });

    }

  }
}
export default beside;