const navFunc = (() => {

  let nav = document.getElementById('nav');
  let navBtn = document.getElementById('navBtn');
  navBtn.addEventListener('click', (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (navBtn.classList.contains('act') == true) {
      nav.classList.remove('act');
      nav.classList.add('none');
      navBtn.classList.remove('act');
    } else {
      nav.classList.remove('none');
      nav.classList.add('act');
      navBtn.classList.add('act');
    }
  });


  let navLink = nav.querySelectorAll('a');
  for (let i = 0; i < navLink.length; i++) {
    navLink[i].addEventListener('click', (event) => {
      nav.classList.remove('act');
      nav.classList.add('none');
      navBtn.classList.remove('act');
    });
  }



})();
export default navFunc;