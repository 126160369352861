import { scrollControlFunc, windowResize, beside, parallax } from './index';

const loadFunc = (() => {
  let loadWrap = document.getElementById('jsLoadWrap');
  scrollControlFunc.noScroll();
  windowResize();

  const startFunc = () => {
    loadWrap.classList.add('no-act');
    scrollControlFunc.returnScroll();
    parallax()
    beside()
  }

  window.addEventListener('load', () => {
    setTimeout(startFunc, 100)
  });

})();
export default loadFunc;